<form [formGroup]="form" (submit)="onSubmit()" [ngClass]="{'-submitted': submitted}" class="form password-forgotten-form">
    <a routerLink="/login" style="display: inline-block; margin-bottom: 30px;">< {{'back_to_login' | translate}}</a>
    <h2>Wachtwoord vergeten</h2>
    <p style="margin: 20px 0;">Vul uw gebruikersnaam in en druk op ‘Wachtwoord resetten’ om een nieuw wachtwoord aan te vragen. U ontvangt per mail direct een nieuw wachtwoord.</p>
    <p style="margin-bottom: 20px; color: #9e005d;">Ook uw gebruikersnaam vergeten? Neem contact op met de afdeling Diagnostiek, tel. (073) 553 3099</p>
    <div class="form-group mb-2">
        <label for="username">{{'username' | translate}}:</label>
        <input formControlName="username"
               class="input -wide"
               id="username"
               type="text">
    </div>
    <p *ngIf="!form.valid && submitted" class="error-text">
        {{'errors.required' | translate}}
    </p>
    <p *ngIf="error && error.status === 404" class="error-text">
        {{'errors.user_not_found' | translate}}
    </p>
    <p *ngIf="error && error.status !== 401 && error.status !== 404" class="error-text">
        {{'errors.server_communication' | translate}}
    </p>
    <button type="submit" class="mt-1 btn-primary -wide">
        {{'reset_password' | translate}}
    </button>
</form>
