<h2 class="h2 mb-1 -primary">
    {{'shipping_address_details' | translate}}
</h2>
<form [formGroup]="form" [ngClass]="{'-submitted': submitted}" (submit)="onSubmit()" class="form checkout-form">
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="company">{{'company' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="company" formControlName="deliveryCompanyName" type="text" class="input -wide" disabled>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="department">{{'department' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="department" formControlName="deliveryDepartmentName" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="deliveryFor">{{'delivery_for' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="deliveryFor" formControlName="deliveryFor" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="email">{{'email' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="email" formControlName="deliveryEmail" type="email" class="input -wide" disabled>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="phone">{{'phone_number' | translate}}</label>
        </div>

        <div class="col-8 col-xs-12">
            <input id="phone" formControlName="deliveryPhoneNumber" type="text" class="input -wide" disabled>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="postcode">{{'postal_code' | translate}}</label>
        </div>
        <div class="col-2 col-xs-12">
            <input id="postcode" formControlName="deliveryPostCode" type="text" class="input -wide" disabled>
        </div>
        <div class="col-3 col-xs-6 form-group_child">
            <div class="row">
                <div class="col-6 col-xs-12 v-center">
                    <label for="houseNumber">{{'house_number' | translate}}</label>
                </div>
                <div class="col-6 col-xs-12">
                    <input id="houseNumber" formControlName="deliveryHouseNumber" type="text" class="input -wide" disabled>
                </div>
            </div>
        </div>
        <div class="col-3 col-xs-6 form-group_child">
            <div class="row">
                <div class="col-6 col-xs-12 v-center">
                    <label for="aptNumber">{{'apt_number' | translate}}</label>
                </div>
                <div class="col-6 col-xs-12">
                    <input id="aptNumber" formControlName="deliveryHouseNumberAddition" type="text" class="input -wide" disabled>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="street">{{'street' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="street" formControlName="deliveryStreet" type="email" class="input -wide" disabled>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="city">{{'city' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="city" formControlName="deliveryCity" type="email" class="input -wide" disabled>
        </div>
    </div>
    <div class="row mb-1 form-group" *ngIf="isRequesterCodeNeeded">
        <div class="col-4 col-xs-12 v-center">
            <label for="requesterCode">{{'requester_code' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="requesterCode" formControlName="requesterCode" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group" *ngIf="isOrderNoteNeeded">
        <div class="col-4 col-xs-12 v-center">
            <label for="deliveryNote">{{'delivery_note' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <textarea formControlName="deliveryNote" class="form-control" id="deliveryNote" rows="4"></textarea>
        </div>
    </div>
    <div class="row mb-1">
        <div class="col-12">
            <p class="error-text" *ngIf="submitted && !form.valid">{{'errors.required' | translate}}</p>
        </div>
    </div>
    <div class="row mb-1">
        <div class="col-8 col-xs-12 offset-4 submit-button">
            <button class="btn-primary" type="submit" [disabled]="loading">
                {{'buttons.send' | translate}}
            </button>
        </div>
    </div>
</form>
