import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleBtnComponent } from './toggle-btn/toggle-btn.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ToggleBtnComponent
    ],
    exports: [
        ToggleBtnComponent
    ]
})
export class SharedModule {
}
