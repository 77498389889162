import {Component, OnInit} from "@angular/core";
import {ArticlesService} from "../articles/_services/articles.service";
import {ArticleGroup} from "../articles/_models/article-group.model";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
    articleGroups: ArticleGroup[] = [];
    groupQuery: string;
    nameQuery: string;
    expanded = true;

    constructor(private $articles: ArticlesService) {}

    ngOnInit() {
        this.setArticlesGroup();
    }

    setArticlesGroup() {
        this.$articles.getArticleGroupsNoCache().then((articleGroups: ArticleGroup[]) => {
            this.articleGroups = articleGroups;
        });
    }

    search(param, query) {
        this.$articles.setArticles({[param]: query});
    }

    searchQuery(query) {
        this.$articles.searchArticles(query);
    }

    reset(queries: string[]) {
        queries.forEach(value => {
            this[`${value}Query`] = null;
        });
    }
}
