import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArticlesOverviewComponent } from "./articles-overview/articles-overview.component";
import { SearchModule } from "../search/search.module";
import { CartModule } from "../cart/cart.module";
import { ArticleComponent } from "./article-details/article.component";
import { ArticlesService } from "./_services/articles.service";
import { FormsModule } from "@angular/forms";
import { NumberDirective } from "./_directives/number.directive";
import { LanguagesModule } from "../languages/languages.module";
import "mousetrap";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    imports: [
        CommonModule,
        SearchModule,
        FormsModule,
        CartModule,
        LanguagesModule,
        MatCheckboxModule,
    ],
    declarations: [
        ArticlesOverviewComponent,
        ArticleComponent,
        NumberDirective,
    ],
    exports: [ArticlesOverviewComponent],
    providers: [ArticlesService],
})
export class ArticlesModule {}
