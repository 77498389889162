import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { ApiService } from "../../core/_services/api.service";
import { BehaviorSubject } from "rxjs";
import { User } from "../_models/user";

@Injectable()
export class AuthService {
    private _token: string;
    private _user: User;
    authenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private $api: ApiService,
        private router: Router,
    ) {
        this.init();
    }

    init() {
        this._user = this.user;
        this._token = this.token;
        this.authenticated.next(!!this.token);
    }

    login(user: any) {
        return this.$api.post("shop/login", user).then((data) => {
            this.setData(data);
            this.authenticated.next(true);
            return data;
        });
    }

    logout() {
        this.reset();
        this.authenticated.next(false);
        this.router.navigate(["/login"]);
    }

    register(user: any) {
        return this.$api.post("shop/register", user).then((data) => {
            return data;
        });
    }

    passwordForgotten(user: any) {
        return this.$api.post("shop/password-forgotten", user).then((data) => {
            return data;
        });
    }

    changePassword(passwordArray: any) {
        let userId = this.user.id;
        return this.$api
            .put(`api/customers/${userId}/change-password`, passwordArray)
            .then((data) => {
                return data;
            });
    }

    setData(data) {
        this.user = data.user;
        this.token = data.token;
    }

    get token() {
        try {
            return this._token || localStorage.getItem("token");
        } catch (e) {
            return null;
        }
    }

    set token(token) {
        this._token = token;
        try {
            localStorage.setItem("token", token);
        } catch (e) {
            console.error("Local storage is not supported");
        }
    }

    set user(user) {
        this._user = new User(user);
        try {
            localStorage.setItem("user", JSON.stringify(this._user));
        } catch (e) {
            console.error("Local storage is not supported");
        }
    }

    get user() {
        try {
            return (
                this._user || new User(JSON.parse(localStorage.getItem("user")))
            );
        } catch (e) {
            return null;
        }
    }

    reset() {
        this._token = null;
        this._user = null;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    }
}
