<form [formGroup]="form" (submit)="onSubmit()" [ngClass]="{'-submitted': submitted}" class="form password-change-form">
    <a routerLink="/" style="display: inline-block; margin-bottom: 30px;">< {{'buttons.back' | translate}}</a>
    <div class="form-group mb-2">
        <label for="currentPassword">{{'old_password' | translate}}:</label>
        <input formControlName="currentPassword"
               class="input -wide"
               id="currentPassword"
               type="password">
    </div>
    <div class="form-group mb-2">
        <label for="password">{{'new_password' | translate}}:</label>
        <input formControlName="password"
               class="input -wide"
               id="password"
               type="password">
    </div>
    <div class="form-group mb-2">
        <label for="passwordRepeat">{{'password_repeat' | translate}}:</label>
        <input formControlName="passwordRepeat"
               class="input -wide"
               id="passwordRepeat"
               type="password">
    </div>
    <p class="error-text" *ngIf="submitted && form.get('passwordRepeat').errors && form.get('passwordRepeat').errors['isMatching'] === false">
        {{'errors.not_matching_passwords' | translate}}
    </p>
    <p *ngIf="!form.valid && submitted && form.get('passwordRepeat').errors && form.get('passwordRepeat').errors['isMatching'] !== false" class="error-text">
        {{'errors.required' | translate}}
    </p>
    <p *ngIf="error?.status === 401" class="error-text">
        {{'errors.bad_credentials' | translate}}
    </p>
    <p *ngIf="error && error.status !== 401" class="error-text">
        {{error.message}}
    </p>
    <button type="submit" class="mt-1 btn-primary -wide">
        {{'buttons.submit_change_pwd' | translate}}
    </button>
</form>
