<form [formGroup]="form" (submit)="onSubmit()" [ngClass]="{'-submitted': submitted}" class="form register-form">
    <a routerLink="/login" style="display: inline-block; margin-bottom: 30px;">< {{'back_to_login' | translate}}</a>

    <h2>Registratiepagina webshop Jeroen Bosch Diagnostiek</h2>
    <p style="margin: 20px 0;">Om in de webshop van Jeroen Bosch Diagnostiek bestellingen te kunnen plaatsen heeft u een gebruikersaccount nodig. Deze kunt u aanvragen middels onderstaand formulier. Binnen 2 werkdagen ontvangt u per mail nader bericht betreffende uw gebruikersaccount.</p>

    <div class="form-group mb-2">
        <label for="username">{{'username' | translate}}* :</label>
        <input formControlName="username"
               maxlength="25"
               class="input -wide"
               id="username"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('username').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('username')">{{message === 'Deze waarde wordt al gebruikt.' ? 'Gebruikersnaam is al in gebruik' : message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="email">{{'email' | translate}}*:</label>
        <input formControlName="email"
               class="input -wide"
               id="email"
               type="email">
        <p *ngIf="validationError && validationError.getFieldErrors('email').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('email')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="companyName">{{'company_name' | translate}}*:</label>
        <input formControlName="companyName"
               class="input -wide"
               id="companyName"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('companyName').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('companyName')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="phoneNumber">{{'phone_number' | translate}}*:</label>
        <input formControlName="phoneNumber"
               class="input -wide"
               id="phoneNumber"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('phoneNumber').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('phoneNumber')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="street">{{'street' | translate}}*:</label>
        <input formControlName="street"
               class="input -wide"
               id="street"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('street').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('street')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="houseNumber">{{'house_number' | translate}}*:</label>
        <input formControlName="houseNumber"
               class="input -wide"
               id="houseNumber"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('houseNumber').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('houseNumber')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="houseNumberAddition">{{'apt_number' | translate}}:</label>
        <input formControlName="houseNumberAddition"
               class="input -wide"
               id="houseNumberAddition"
               type="text">
    </div>
    <div class="form-group mb-2">
        <label for="postcode">{{'postal_code' | translate}}*:</label>
        <input formControlName="postCode"
               class="input -wide"
               id="postcode"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('postcode').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('postcode')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <label for="city">{{'city' | translate}}*:</label>
        <input formControlName="city"
               class="input -wide"
               id="city"
               type="text">
        <p *ngIf="validationError && validationError.getFieldErrors('city').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('city')">{{message}}<br/></span>
        </p>
    </div>
    <div class="form-group mb-2">
        <mat-checkbox formControlName="isUsingPOCT">{{'is_using_poct' | translate}}</mat-checkbox>
    </div>
    <div class="form-group mb-2">
        <mat-checkbox formControlName="privacyConditionsAccepted">
            <span [innerHTML]="'privacy_conditions' | translate"></span>*
        </mat-checkbox>
        <p *ngIf="validationError && validationError.getFieldErrors('privacyConditionsAccepted').length > 0" class="error-text">
            <span *ngFor="let message of validationError.getFieldErrors('privacyConditionsAccepted')">{{message}}<br/></span>
        </p>
    </div>
    <p *ngIf="!form.valid && submitted" class="error-text">
        {{'errors.required' | translate}}
    </p>
    <p *ngIf="error && error.status !== 401" class="error-text">
        {{'errors.server_communication' | translate}}
    </p>
    <button type="submit" class="mt-1 btn-primary -wide">
        {{'register' | translate}}
    </button>
</form>
