<div class="search">
    <h2 class="h2 h-between -primary mb-0">
        {{'search' | translate | titlecase}}
        <app-toggle-btn (onExpand)="expanded = $event"></app-toggle-btn>
    </h2>
    <div class="mt-1" [ngClass]="{'hidden-lg': !expanded}">
        <div class="search-group row mb-1">
            <div class="col-3 col-xl-12 v-center">
                <label for="article-group">{{'article.group' | translate}}</label>
            </div>
            <div class="col-9 col-xl-12 search-control">
                <mat-select class="select"
                            id="article-group"
                            [disabled]="articleGroups.length === 0"
                            [(ngModel)]="groupQuery"
                            [placeholder]="'no_selection' | translate">
                    <mat-option *ngFor="let articleGroup of articleGroups" [value]="articleGroup.id">
                        {{articleGroup.name}}
                    </mat-option>
                </mat-select>
                <button class="search_btn btn-primary"
                        [disabled]="!groupQuery"
                        (click)="search('articleGroup.id', groupQuery); reset(['name'])">
                    {{'buttons.seek' |
                    translate}}
                </button>
            </div>
        </div>
        <div class="search-group row mb-1">
            <div class="col-3 col-xl-12 v-center">
                <label for="article-name">{{'article.name' | translate}}</label>
            </div>
            <div class="col-9 col-xl-12 search-control">
                <input [(ngModel)]="nameQuery" id="article-name" class="input" type="text">
                <button class="search_btn btn-primary"
                        [disabled]="!nameQuery"
                        (click)="searchQuery(nameQuery); reset(['group']);">
                    {{'buttons.seek' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
