import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Article } from "../_models/article.model";
import { CartService } from "../../cart/_services/cart.service";
import {
    ButtonsConfig,
    ButtonsStrategy,
    ButtonType,
    Description,
    DescriptionStrategy,
    Image,
    ModalGalleryConfig,
    ModalGalleryRef,
    ModalGalleryService,
} from "@ks89/angular-modal-gallery";
import { ArticlesService } from "../_services/articles.service";

@Component({
    selector: "app-article",
    templateUrl: "./article.component.html",
    styleUrls: ["./article.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ArticleComponent implements OnInit {
    @Input("article") article: Article;
    quantity: any = 1;
    image: Image;
    imageDescription: Description;
    buttonsConfig: ButtonsConfig = {
        visible: true,
        strategy: ButtonsStrategy.CUSTOM,
        buttons: [
            {
                className: "close-btn",
                type: ButtonType.CLOSE,
            },
        ],
    };

    constructor(
        private $cart: CartService,
        private $articles: ArticlesService,
        private modalGalleryService: ModalGalleryService,
    ) {}

    ngOnInit() {
        this.image = new Image(this.article.id, {
            img: this.article.image,
            title: this.article.name,
        });
        this.imageDescription = {
            strategy: DescriptionStrategy.ALWAYS_HIDDEN,
        };
    }

    addToCart() {
        this.quantity = parseFloat(this.quantity);
        this.$cart.addArticle(this.article, this.quantity).catch(() => {});
    }

    subscribeNotification(event) {
        this.$articles.updateStockNotifications(this.article.id, event.checked);
    }

    maxQuantity() {
        return 999;
        // return this.article.articleGroup.hasInfiniteStock ? 999 : this.article.stock;
        // return this.article.stock >= 999 ? 999 : this.article.stock;
    }

    openModal(article: Article) {
        const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
            id: article.id,
            images: [this.image],
            currentImage: this.image,
            libConfig: {
                slideConfig: {
                    infinite: false,
                    sidePreviews: { show: false },
                },
                currentImageConfig: {
                    description: this.imageDescription,
                },
                buttonsConfig: this.buttonsConfig,
                previewConfig: { visible: false },
                dotsConfig: { visible: false },
            },
        } as ModalGalleryConfig) as ModalGalleryRef;
    }
}
