<div class="row">
    <div class="col-5 col-lg-12">
        <aside class="sidebar">
            <div class="sidebar-inner">
                <app-search></app-search>
            </div>
            <app-cart></app-cart>
        </aside>
    </div>
    <div class="col-7 col-lg-12">
        <main class="main">
            <div class="search-instructions" *ngIf="!$articles.articles">
                {{'texts.search_instructions' | translate}}
            </div>
            <ng-container *ngIf="$articles.articles && !$articles.articles.length">
                <h2 *ngIf="!thanksMessage" class="h2 mb-1 -primary">
                    {{'texts.no_search_result' | translate}}
                </h2>
                <ng-container *ngIf="thanksMessage">
                    <h2 class="h2 mb-1 -primary">
                        {{'texts.thank_message'| translate}}
                    </h2>
                    <img src="assets/img/delivery-box.svg" alt="">
                </ng-container>
            </ng-container>

            <ng-container *ngIf="$articles.articles && $articles.articles.length">
                <h2 class="h2 mb-1 -primary">
                    {{'search_result' | translate}}
                </h2>
                <app-article *ngFor="let article of $articles.articles" [article]="article">
                </app-article>
            </ng-container>
        </main>
    </div>
    <div class="checkout-block show-lg" *ngIf="$cart.items.length">
        <button class="btn-primary" (click)="goToCheckout()">
            {{'buttons.to_checkout' | translate}}
        </button>
    </div>
</div>
