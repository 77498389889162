import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {NotificationService} from "../../core/_services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {LanguagesService} from "../../languages/_services/languages.service";

@Component({
    selector: "app-password-forgotten",
    templateUrl: "./password-forgotten.component.html",
    styleUrls: ["./password-forgotten.component.scss"]
})
export class PasswordForgottenComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    error = null;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private $auth: AuthService,
        private $notify: NotificationService,
        private translate: TranslateService,
        private $language: LanguagesService // DO NOT REMOVE, needed for translations to work
    ) {
        this.initForm();
    }

    ngOnInit() {
    }

    initForm() {
        this.form = this.fb.group({
            username: this.fb.control("", Validators.required)
        });

        this.form.valueChanges.subscribe(() => {
            this.error = null;
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.$auth.passwordForgotten(this.form.value).then((data: any) => {
                this.$notify.success(this.translate.instant("texts.customer_password_reset"));
                this.router.navigate(["/login"]);
            }).catch(err => {
                this.error = err;
            });
        }
    }
}
