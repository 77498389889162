import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {ApiValidationError} from "../../core/_services/api.service";
import {NotificationService} from "../../core/_services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {LanguagesService} from "../../languages/_services/languages.service";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    error = null;
    validationError: ApiValidationError | null = null;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private $auth: AuthService,
        private $notify: NotificationService,
        private translate: TranslateService,
        private $language: LanguagesService // DO NOT REMOVE, needed for translations to work
    ) {
        this.initForm();
    }

    ngOnInit() {
    }

    initForm() {
        this.form = this.fb.group({
            username: this.fb.control("", [Validators.required, Validators.maxLength(25)]),
            email: this.fb.control("", [Validators.required, Validators.email]),
            companyName: this.fb.control("", Validators.required),
            phoneNumber: this.fb.control("", Validators.required),
            street: this.fb.control("", Validators.required),
            houseNumber: this.fb.control("", Validators.required),
            houseNumberAddition: this.fb.control(""),
            postCode: this.fb.control("", Validators.required),
            city: this.fb.control("", Validators.required),
            isUsingPOCT: this.fb.control(false, Validators.required),
            privacyConditionsAccepted: this.fb.control(false, Validators.requiredTrue)
        });

        this.form.valueChanges.subscribe(() => {
            this.error = null;
            this.validationError = null;
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            let formValue = this.form.value;
            let customerData = {
                username: formValue.username,
                email: formValue.email,
                companyName: formValue.companyName,
                phoneNumber: formValue.phoneNumber,
                street: formValue.street,
                houseNumber: formValue.houseNumber,
                houseNumberAddition: formValue.houseNumberAddition,
                postCode: formValue.postCode,
                city: formValue.city,
                isUsingPOCT: formValue.isUsingPOCT
            };
            this.$auth.register(customerData).then((data: any) => {
                this.$notify.success(this.translate.instant("texts.customer_registered"));
                this.router.navigate(["/login"]);
            }).catch(err => {
                if (err instanceof ApiValidationError) {
                    this.validationError = err;
                } else {
                    this.error = err;
                }
            });
        }
    }
}
