export class User {
    email: string;
    id: number;
    role: string;
    username: string;
    articleStockNotifications: any[] = [];
    city: string;
    companyName: string;
    country: string | null;
    departmentName: string | null;
    firstName: string | null;
    initials: string | null;
    houseNumber: string;
    houseNumberAddition: string;
    isActive: boolean;
    language: string;
    lastName: string | null;
    middleName: string | null;
    phoneNumber: string;
    postCode: string;
    street: string;
    group: any;

    constructor(user) {
        this.email = user.email;
        this.id = user.id;
        this.role = user.role;
        this.username = user.username;
        this.initials = user.initials;
        this.articleStockNotifications = user.articleStockNotifications;
        this.city = user.city;
        this.companyName = user.companyName;
        this.country = user.country;
        this.departmentName = user.departmentName;
        this.firstName = user.firstName;
        this.houseNumber = user.houseNumber;
        this.houseNumberAddition = user.houseNumberAddition;
        this.isActive = user.isActive;
        this.language = user.language;
        this.lastName = user.lastName;
        this.middleName = user.middleName;
        this.phoneNumber = user.phoneNumber;
        this.postCode = user.postCode;
        this.street = user.street;
        this.group = user.group;
    }
}
