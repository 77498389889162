<div class="login-page">
    <div class="intro">
        <p>Welkom op de webpagina voor het bestellen van materialen t.b.v. diagnostiek in het Jeroen Bosch Ziekenhuis.</p>
        <p style="margin: 20px 0;">Heeft u een gebruikersaccount? Voer uw gebruikersnaam en wachtwoord in om in te loggen.</p>
        <p>Heeft u nog geen gebruikersaccount? Vraag deze aan via de link ‘Registreer’. Uw aanvraag voor registratie wordt ter goedkeuring voorgelegd aan het Jeroen Bosch Ziekenhuis: binnen 2 werkdagen ontvangt u hierover per mail nader bericht.</p>
    </div>

    <form [formGroup]="form" (submit)="onSubmit()" [ngClass]="{'-submitted': submitted}" class="form login-form">
        <div class="form-group mb-2">
            <label for="username">{{'username' | translate}}:</label>
            <input formControlName="username"
                   class="input -wide"
                   id="username"
                   type="text">
        </div>
        <div class="form-group mb-2">
            <label for="password">{{'password' | translate}}:</label>
            <input formControlName="password"
                   class="input -wide"
                   id="password"
                   type="password">
        </div>
        <p *ngIf="!form.valid && submitted" class="error-text">
            {{'errors.required' | translate}}
        </p>
        <p *ngIf="error?.status === 401 && error?.message === 'User inactive'" class="error-text">
            {{'errors.user_inactive' | translate}}
        </p>
        <p *ngIf="error?.status === 401 && error?.message !== 'User inactive'" class="error-text">
            {{'errors.bad_credentials' | translate}}
        </p>
        <p *ngIf="error && error.status !== 401" class="error-text">
            {{'errors.server_communication' | translate}}
        </p>
        <button type="submit" class="mt-1 btn-primary -wide">
            {{'login' | translate}}
        </button>

        <div style="margin: 20px 0;"><a routerLink="/register">{{'register' | translate}}</a></div>
        <div><a routerLink="/password-forgotten">{{'password_forgotten' | translate}}</a></div>
    </form>
</div>
