import { tap } from "rxjs/operators";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { AuthService } from "./auth.service";
import { NotificationService } from "../../core/_services/notification.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private inj: Injector,
        private $notify: NotificationService,
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const _auth = this.inj.get(AuthService);

        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${_auth.token}`,
            },
        });

        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do stuff with response if you want
                    }
                },
                (err: any) => {
                    // logout if unauthorised
                    if (err instanceof HttpErrorResponse) {
                        console.error(err);
                        if (
                            err.status === 401 &&
                            err.error.message !== "Ongeldige inloggegevens." &&
                            err.error.message !== "Bad credentials" &&
                            err.error.message !== "Invalid credentials." &&
                            err.error.message !== "User inactive"
                        ) {
                            this.$notify.sessionExpired();
                            _auth.logout();
                        }
                    }
                },
            ),
        );
    }
}
