import { Injectable } from "@angular/core";
import { Article } from "../_models/article.model";
import { CartService } from "../../cart/_services/cart.service";
import { ApiService } from "../../core/_services/api.service";
import { ArticleGroup } from "../_models/article-group.model";
import { AuthService } from "../../auth/_services/auth.service";
import { ArticleStockNotifications } from "../_models/article-stock-notifications.model";

@Injectable()
export class ArticlesService {
    articles: Article[] | null = null;
    articleGroups: ArticleGroup[] = [];
    stockNotifications: ArticleStockNotifications =
        new ArticleStockNotifications([]);

    constructor(
        private $cart: CartService,
        private $auth: AuthService,
        private $api: ApiService,
    ) {
        this.getArticleGroups();
        this.setStockNotifications();
    }

    setStockNotifications() {
        this.stockNotifications = new ArticleStockNotifications(
            this.$auth.user.articleStockNotifications,
        );
    }

    updateStockNotifications(articleId: number, enableNotification: boolean) {
        if (enableNotification) {
            this.stockNotifications.addArticle(articleId);
        } else {
            this.stockNotifications.removeArticle(articleId);
        }

        this.$api
            .put(
                `api/customers/${this.$auth.user.id}/article_stock_notifications`,
                this.stockNotifications,
            )
            .then((user: any) => {
                this.$auth.user = user;
            });
    }

    getArticleGroups() {
        return new Promise((resolve) => {
            if (this.articleGroups.length) {
                resolve(this.articleGroups);
            } else {
                return this.$api
                    .get("api/article_groups")
                    .then((articleGroups: any[]) => {
                        this.articleGroups = articleGroups.map(
                            (item) => new ArticleGroup(item),
                        );
                        resolve(this.articleGroups);
                    })
                    .catch((err) => {
                        resolve([]);
                    });
            }
        });
    }

    getArticleGroupsNoCache() {
        return new Promise((resolve) => {
            return this.$api
                .get("api/article_groups")
                .then((articleGroups: any[]) => {
                    this.articleGroups = articleGroups.map(
                        (item) => new ArticleGroup(item),
                    );
                    resolve(this.articleGroups);
                })
                .catch((err) => {
                    console.error(err);
                    resolve([]);
                });
        });
    }

    setArticles(params) {
        this.$api.get("api/articles", params).then((articles: any[]) => {
            this.articles = articles.map((item) => new Article(item));
            this.syncArticlesWithStockNotifications();
        });
    }

    searchArticles(query: string) {
        this.$api
            .get("api/articles/search", { query: query })
            .then((articles: any[]) => {
                this.articles = articles.map((item) => new Article(item));
                this.syncArticlesWithStockNotifications();
            });
    }

    syncArticlesWithStockNotifications() {
        this.articles.forEach((article: Article) => {
            this.stockNotifications.articles.forEach((stockNotification) => {
                if (article.id === stockNotification.article) {
                    article.stockNotification = true;
                }
            });
        });
    }
}
