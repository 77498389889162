import { ArticleGroup } from "./article-group.model";

export class Article {
    id: number;
    isPackage: boolean;
    name: string;
    packingUnit: string;
    fullCode: string;
    articleGroup: ArticleGroup;
    isRequesterCodeNeeded: boolean;
    emailAddressOrderNotification: string;
    description: string;
    eanNumber: string;
    price: number;
    orderLimit: number;
    stockStatus: string;
    image: string;
    thumbnail: string;
    status: string;
    stockNotification = false;

    constructor(article: any) {
        this.id = article.id;
        this.isPackage = article.isPackage;
        this.name = article.name;
        this.packingUnit = article.packingUnit;
        this.fullCode = article.fullCode;
        this.articleGroup = article.articleGroup
            ? new ArticleGroup(article.articleGroup)
            : null;
        this.isRequesterCodeNeeded = article.isRequesterCodeNeeded;
        this.emailAddressOrderNotification =
            article.emailAddressOrderNotification;
        this.description = article.description;
        this.eanNumber = article.eanNumber;
        this.price = article.price;
        this.orderLimit = article.orderLimit;
        this.stockStatus = article.stockStatus;
        this.image = article.originalImageUrl;
        this.thumbnail = article.thumbnailImageUrl;
        this.status = article.status;
    }
}
