import {Component, OnInit} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {CheckoutService} from "../_services/checkout.service";
import {ArticlesService} from "../../articles/_services/articles.service";
import {CartService} from "../../cart/_services/cart.service";
import {ApiService} from "../../core/_services/api.service";
import {NotificationService} from "../../core/_services/notification.service";
import {AuthService} from "../../auth/_services/auth.service";
import {User} from "../../auth/_models/user";

@Component({
    selector: "app-checkout-form",
    templateUrl: "./checkout-form.component.html",
    styleUrls: ["checkout-form.component.scss"],
})
export class CheckoutFormComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    loading = false;
    user: User;
    isRequesterCodeNeeded = false;
    isOrderNoteNeeded = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private $api: ApiService,
        private $articles: ArticlesService,
        private $auth: AuthService,
        private $cart: CartService,
        private $notify: NotificationService,
        private $checkout: CheckoutService
    ) {
    }

    ngOnInit() {
        this.user = this.$auth.user;
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            deliveryCompanyName: [this.user.companyName],
            deliveryDepartmentName: [this.user.departmentName],
            deliveryFor: [""],
            deliveryEmail: [
                this.user.email,
                [Validators.required, this.validateEmail()],
            ],
            deliveryPhoneNumber: [this.user.phoneNumber, Validators.required],
            deliveryPostCode: [this.user.postCode, Validators.required],
            deliveryHouseNumber: [this.user.houseNumber, Validators.required],
            deliveryHouseNumberAddition: [this.user.houseNumberAddition],
            deliveryStreet: [this.user.street, Validators.required],
            deliveryCity: [this.user.city, Validators.required],
            requesterCode: [""],
            deliveryNote: [""],
        });

        let cart = this.$cart.getCartItems();
        for (let i = 0; i < cart.length; i++) {
            let cartItem = cart[i];
            if (cartItem.article.isRequesterCodeNeeded) {
                this.isRequesterCodeNeeded = true;
                this.form.get("requesterCode").setValidators(Validators.required);
            } else {
                this.form.get("requesterCode").setValidators([]);
            }

            if (cartItem.article.articleGroup.isOrderNoteNeeded) {
                this.isOrderNoteNeeded = true;
            }
        }
    }

    validateEmail() {
        return Validators.pattern(
            // tslint:disable-next-line:max-line-length
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.loading = true;
            this.$api
                .post("api/orders", {
                    deliveryDepartmentName: this.form.value.deliveryDepartmentName,
                    deliveryFor: this.form.value.deliveryFor,
                    requesterCode: this.form.value.requesterCode,
                    deliveryNote: this.form.value.deliveryNote
                })
                .then((data) => {
                    this.$cart.clearCart();
                    this.$articles.articles = [];
                    this.$checkout.success = true;
                    this.router.navigate(["../"]);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$notify.serverError();
                });
        }
    }
}
