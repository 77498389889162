export class ArticleGroup {
    id: number;
    name: string;
    isOrderNoteNeeded: boolean;

    constructor(articleGroup: any) {
        this.id = articleGroup.id;
        this.name = articleGroup.name;
        this.isOrderNoteNeeded = articleGroup.isOrderNoteNeeded;
    }
}
