import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { ReactiveFormsModule } from "@angular/forms";
import { LanguagesModule } from "../languages/languages.module";
import { AuthService } from "./_services/auth.service";
import { AuthInterceptor } from "./_services/auth.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from "./_services/auth.guard";
import { UnauthGuard } from "./_services/unauth.guard";
import { RegisterComponent } from "./register/register.component";
import { PasswordForgottenComponent } from "./password-forgotten/password-forgotten.component";
import { RouterModule } from "@angular/router";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PasswordChangeComponent } from "./password-change/password-change.component";
import { LanguagesService } from "../languages/_services/languages.service";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LanguagesModule,
        RouterModule,
        MatCheckboxModule,
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        PasswordForgottenComponent,
        PasswordChangeComponent,
    ],
    exports: [LoginComponent],
    providers: [
        AuthService,
        LanguagesService,
        AuthGuard,
        UnauthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
})
export class AuthModule {}
