import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchComponent } from "./search.component";
import { MatSelectModule } from "@angular/material/select";
import { LanguagesModule } from "../languages/languages.module";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        LanguagesModule,
        SharedModule,
    ],
    declarations: [SearchComponent],
    exports: [SearchComponent],
})
export class SearchModule {}
