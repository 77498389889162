<div class="cart">
    <div class="h-between v-center">
        <h2 class="h2 -primary mb-0">{{'shopping_cart' | translate}}</h2>
        <span class="v-center">
            <img class="cart_badge" src="assets/img/cart.svg" alt="">
            <app-toggle-btn (onExpand)="expanded = $event"></app-toggle-btn>
        </span>
    </div>
    <div class="mt-3" [ngClass]="{'hidden-lg': !expanded}">
        <strong *ngIf="!$cart.items.length" class="primary-text">
            {{'texts.cart_empty' | translate}}
        </strong>
        <ng-container *ngIf="$cart.items.length">
            <div class="cart-list">
                <div class="h-between mb-1">
                    <strong class="primary-text">
                        {{'article.data' | translate | titlecase}}
                    </strong>
                    <strong class="primary-text mr-1">
                        {{'amount' | translate | titlecase}}
                    </strong>
                </div>
                <app-cart-item *ngFor="let item of $cart.items" [item]="item">
                </app-cart-item>
            </div>
            <div class="h-end">
                <button *ngIf="!$cart.readonly" class="btn-primary" (click)="goToCheckout()" [disabled]="isCheckoutDisabled()">
                    {{'buttons.to_checkout' | translate}}
                </button>
                <button *ngIf="$cart.readonly" class="btn-primary" (click)="goBack()">
                    {{'buttons.back' | translate}}
                </button>
            </div>
        </ng-container>
    </div>
</div>
