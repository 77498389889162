import { Injectable } from "@angular/core";
import { Article } from "../../articles/_models/article.model";
import { CartItem } from "../_models/cart-item.model";
import { Subject } from "rxjs";
import { ApiService } from "../../core/_services/api.service";
import { AuthService } from "../../auth/_services/auth.service";
import * as _ from "lodash";
import { Cart } from "../_models/cart.model";
import { NotificationService } from "../../core/_services/notification.service";
import { LanguagesService } from "../../languages/_services/languages.service";

@Injectable()
export class CartService {
    items: CartItem[] = [];
    onCartItemRemoved = new Subject<CartItem>();
    readonly = false;
    loading = false;

    constructor(
        private $api: ApiService,
        private $auth: AuthService,
        private $language: LanguagesService,
        private $notify: NotificationService,
    ) {
        this.getCart();
        this.$language.onChange.subscribe(() => {
            this.getCart();
        });
    }

    sendCart(items: CartItem[]) {
        let userId = this.$auth.user.id;
        let cart = new Cart(items);
        this.loading = true;
        return this.$api
            .put(`api/customers/${userId}/cart`, cart)
            .then((apiCart: any) => {
                this.loading = false;
                return this.assignCart(apiCart);
            })
            .catch((err) => {
                this.loading = false;
                this.$notify.serverError();
            });
    }

    assignCart(cart: any) {
        this.items = cart.items.map((item) => {
            return new CartItem(item);
        });
    }

    getCart() {
        let userId = this.$auth.user.id;
        return this.$api
            .get(`api/customers/${userId}/cart`)
            .then((cart: any) => {
                this.assignCart(cart);
            })
            .catch(() => {
                this.items = [];
            });
    }

    getCartItems() {
        return this.items;
    }

    addArticle(article: Article, quantity: number) {
        let items = _.cloneDeep(this.items);
        const cartItem = items.find((item) => item.article.id === article.id);
        if (cartItem) {
            cartItem.quantity += quantity;
        } else {
            items.push(new CartItem({ article, quantity }));
        }
        return this.sendCart(items);
    }

    removeItem(cartItem: CartItem) {
        this.onCartItemRemoved.next(cartItem);
        _.remove(this.items, (item) => item === cartItem);
        this.sendCart(this.items);
    }

    missedItemMessage(item) {
        this.$notify.error(
            `Can't place <strong>${item.article.name}</strong> in cart`,
        );
    }

    outOfStockItemMessage(item) {
        let text = `Can't place <strong>${item.quantity} x ${item.article.name}</strong> in cart.
                    <br> <strong>${item.article.stock}</strong> was added`;
        this.$notify.error(text);
    }

    getItemByArticleId(id: number): CartItem {
        return this.items.find((item) => item.article.id === id);
    }

    clearCart() {
        this.items = [];
        this.sendCart(this.items);
    }

    hasError() {
        return this.items.some((item) => item.errors.length > 0);
    }
}
