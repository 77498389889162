import {Component, OnInit} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {LanguagesService} from "../../languages/_services/languages.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "app-password-change",
    templateUrl: "./password-change.component.html",
    styleUrls: ["./password-change.component.scss"]
})

export class PasswordChangeComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    error = null;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private $auth: AuthService,
        private $language: LanguagesService,
        private toastr: ToastrService,
        private $translate: TranslateService
    ) {
        this.initForm();
    }

    ngOnInit() {
    }

    initForm() {
        this.form = this.fb.group({
            currentPassword: this.fb.control('', Validators.required),
            password: this.fb.control("", Validators.required),
            passwordRepeat: this.fb.control("", [
                Validators.required,
                this.matchValues("password"),
            ]),
        });

        this.form.valueChanges.subscribe(() => {
            this.error = null;
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.$auth.changePassword(this.form.value).then((data: any) => {
                this.toastr.success(
                    this.$translate.instant("texts.password_change_success")
                );

                this.router.navigate([`/`]);
            }).catch(err => {
                this.error = err;
            });
        }
    }

    matchValues(
        matchTo: string // name of the control to match to
    ): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return !!control.parent &&
            !!control.parent.value &&
            control.value === control.parent.controls[matchTo].value
                ? null
                : {isMatching: false};
        };
    }
}
