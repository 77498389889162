<div class="cart-item">
    <div class="cart-item_body">
        <p class="primary-text">{{item.article.name}}{{item.article.packingUnit && item.article.packingUnit != "" ? " (" + item.article.packingUnit + ")" : "" }}</p>
        <p class="card-item_description" [innerHTML]="item.article.description"></p>
        <p *ngFor="let error of item.errors">
            <small class="red-text">{{error.message}}</small>
        </p>
    </div>
    <div class="v-center">
        <strong class="primary-text mr-1">{{item.quantity}}</strong>
        <button [ngClass]="{'-hidden': $cart.readonly}"
                class="btn-pure cart-item_remove-btn"
                (click)="remove()"
                [disabled]="$cart.loading || $cart.readonly">
            <img src="assets/img/cross-circle.svg" alt="">
        </button>
    </div>
</div>

