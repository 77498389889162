import {Component, OnDestroy, OnInit} from "@angular/core";
import {CartService} from "../cart/_services/cart.service";


@Component({
    selector: "app-checkout",
    templateUrl: "./checkout.component.html",
    styleUrls: ["./checkout.component.scss"]
})
export class CheckoutComponent implements OnInit, OnDestroy {

    constructor(private $cart: CartService) {


    }

    ngOnInit() {
        this.$cart.readonly = true;
    }


    ngOnDestroy() {
        this.$cart.readonly = false;
    }

}
