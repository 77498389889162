import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ArticlesService} from "../_services/articles.service";
import {CheckoutService} from "../../checkout/_services/checkout.service";
import {Router} from "@angular/router";
import {CartService} from "../../cart/_services/cart.service";

@Component({
    selector: "app-articles-overview",
    templateUrl: "./articles-overview.component.html",
    styleUrls: ["./articles-overview.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ArticlesOverviewComponent implements OnInit {
    thanksMessage = false;

    constructor(
        public $articles: ArticlesService,
        public $cart: CartService,
        private router: Router,
        private $checkout: CheckoutService
    ) {
    }

    ngOnInit() {
        this.thanksMessage = this.$checkout.success;
    }

    goToCheckout() {
        this.router.navigate(["checkout"]);
    }
}
