import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./auth/login/login.component";
import {ArticlesOverviewComponent} from "./articles/articles-overview/articles-overview.component";
import {CheckoutComponent} from "./checkout/checkout.component";
import {CheckoutGuard} from "./checkout/_services/checkout.guard";
import {AuthGuard} from "./auth/_services/auth.guard";
import {UnauthGuard} from "./auth/_services/unauth.guard";
import {RegisterComponent} from "./auth/register/register.component";
import {PasswordForgottenComponent} from "./auth/password-forgotten/password-forgotten.component";
import {PasswordChangeComponent} from "./auth/password-change/password-change.component";

const appRoutes: Routes = [
    {
        path: "",
        component: ArticlesOverviewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [UnauthGuard]
    },
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [UnauthGuard]
    },
    {
        path: "password-forgotten",
        component: PasswordForgottenComponent,
        canActivate: [UnauthGuard]
    },
    {
        path: "checkout",
        component: CheckoutComponent,
        canActivate: [AuthGuard, CheckoutGuard],
    },
    {
        path: "password-change",
        component: PasswordChangeComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
